import styled from 'styled-components';
import { generateGrid, generateCol } from '@layouts/grid';
import { generateBreakpoint } from '../layouts/grid';

export const PlaceholderContainer = styled.div`
    height: 100vh;
    width: 100vw;
    max-width: 100%;
`;

export const StyledSchedule = styled.div`
    > div {
        ${generateGrid(12, 0)}
        background-color: transparent;
    }
    > div > :first-child {
        ${generateCol(12)}
    }
    > div > :nth-child(n + 2) {
        ${generateCol(12)}

        ${generateBreakpoint('lg')(
            `${generateCol(6)}`,
        )}

        > div:first-child > div:first-child {
            ${generateBreakpoint('md')('background-color: transparent')}
        }
    }

    > div > :nth-child(2n + 2) {
        &:after {
            content: '';
            z-index: 3;
        }
    }

    > div > :nth-child(2n + 3) {
        &:before {
            content: '';
            z-index: 3;
        }
    }
`;
