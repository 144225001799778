import { navigate } from '@reach/router';
import { UPDATE_ACTIVE_INDEX, UPDATE_ACTIVE_SLUG } from './constants';
import { selectActiveSlug } from './selectors';

export const UpdateActiveSlug = slug => (dispatch, getState) => {
    const activeSlug = selectActiveSlug(getState());
    let newSlug = slug;
    if (slug === activeSlug) {
        newSlug = '';
        setTimeout(() => {
            navigate('/schedule', {
                state: { noScroll: true, noRefresh: true },
            });
        }, 500);
    } else {
        const newPath = `/schedule/${slug}`;
        setTimeout(() => {
            navigate(newPath, { state: { noScroll: true, noRefresh: true } });
        }, 500);
    }

    return dispatch({
        type: UPDATE_ACTIVE_SLUG,
        payload: newSlug,
    });
};

export const UpdateActiveIndex = index => {
    return {
        type: UPDATE_ACTIVE_INDEX,
        payload: index,
    };
};
