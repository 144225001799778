import React, { useEffect, useState } from 'react';
import { formatGroupDate } from '@src/helpers/date';
import { useMedia } from '@src/helpers/hooks';
import UpcomingShows, { groupUpcomingShows } from '@components/upcoming-shows';
import { LG_BREAKPOINT } from '../layouts/grid';
import {
    selectActiveIndex,
    selectActiveSlug,
} from '../store/schedule/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateActiveSlug, UpdateActiveIndex } from '../store/schedule/actions';

import { StyledSchedule, PlaceholderContainer } from './schedule.styled';

const breakPointsQueries = [
    `(min-width: ${LG_BREAKPOINT}px)`,
    '(min-width: 0)',
];
const breakPointsNames = ['desktop', 'mobile'];

export const ScheduleRender = ({ pageContext }) => {
    const dispatch = useDispatch();
    const { uid, upcomingPerDate, activeShow } = pageContext;
    const activeIndex = useSelector(selectActiveIndex);
    const activeSlug = useSelector(selectActiveSlug);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 500);
    }, []);

    const currentBP = useMedia(breakPointsQueries, breakPointsNames, 'desktop');

    const isMobile = currentBP === 'mobile';

    const groupedDates = groupUpcomingShows(
        upcomingPerDate,
        8,
        isMobile ? 1 : 2,
    );
    useEffect(() => {
        if (activeShow) {
            if (activeIndex === -2 && uid) {
                dispatch(UpdateActiveSlug(uid.replace(/\/+$/, '')));
            }
            const activeDate = formatGroupDate(activeShow.date_start);
            dispatch(
                UpdateActiveIndex(
                    groupedDates.findIndex(
                        i => i.dateNames.indexOf(activeDate) !== -1,
                    ),
                ),
            );
        } else if (activeSlug && activeIndex > -1) {
            dispatch(UpdateActiveSlug(''));
            dispatch(UpdateActiveIndex(-2));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (index, slug) => {
        const transitionBetweenTwo = activeSlug && slug !== activeSlug;
        let newIndex =
            index === activeIndex && !transitionBetweenTwo ? -2 : index;

        if (transitionBetweenTwo) {
            dispatch(UpdateActiveSlug(''));
            dispatch(UpdateActiveIndex(-2));
        }
        setTimeout(
            () => {
                dispatch(UpdateActiveSlug(slug));
                dispatch(UpdateActiveIndex(newIndex));
            },
            transitionBetweenTwo ? 500 : 0,
        );
    };

    return (
        <StyledSchedule>
            {loaded ? (
                <UpcomingShows
                    groupedDates={groupedDates}
                    activeSlug={activeSlug}
                    activeShow={activeShow}
                    activeIndex={activeIndex}
                    isMobile={isMobile}
                    onShowClick={handleClick}
                />
            ) : (
                <PlaceholderContainer />
            )}
        </StyledSchedule>
    );
};

const Schedule = props => {
    if (!props.pageContext) {
        return null;
    }
    return <ScheduleRender {...props} />;
};

export default Schedule;
